import React from 'react'
import { Container } from 'semantic-ui-react'
import Layout from '../components/Layouts/default'

export default function NotFoundPage() {
  return (
    <Layout>
      <Container text>
        <h1>404 - Không tìm thấy trang này</h1>
        <p>Bạn thử check lại đường link nhé!</p>
      </Container>
    </Layout>
  )
}
